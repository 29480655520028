$(function() {

    // Image hover
    $('.itemPic_thumbs a').click(function() {
      var replaceImg = $(this).attr('href');
       $('.itemPic_main img').attr('src',replaceImg);
       return false;
    });
    // Break Category Text
    $('.itemCat_name:contains(" \/ ")').each(function(){
        var txt = $(this).text();
        $(this).html(
            txt.replace(/ \/ /g, '<br><span class="itemCat_en">')
          );
    });
    // News Toggle
    $('.newsItem_txt').hide();
    $('.newsItem_title').click(function(event) {
      $(this).next('.newsItem_txt').slideToggle(100);
    });
});
